/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StorageInfoData
 */
export interface StorageInfoData {
    /**
     * 
     * @type {number}
     * @memberof StorageInfoData
     */
    totalStorage: number;
    /**
     * 
     * @type {number}
     * @memberof StorageInfoData
     */
    processedStorage: number;
    /**
     * 
     * @type {number}
     * @memberof StorageInfoData
     */
    uploadStorage: number;
    /**
     * 
     * @type {number}
     * @memberof StorageInfoData
     */
    tempFolderSize: number;
}

/**
 * Check if a given object implements the StorageInfoData interface.
 */
export function instanceOfStorageInfoData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalStorage" in value;
    isInstance = isInstance && "processedStorage" in value;
    isInstance = isInstance && "uploadStorage" in value;
    isInstance = isInstance && "tempFolderSize" in value;

    return isInstance;
}

export function StorageInfoDataFromJSON(json: any): StorageInfoData {
    return StorageInfoDataFromJSONTyped(json, false);
}

export function StorageInfoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageInfoData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalStorage': json['totalStorage'],
        'processedStorage': json['processedStorage'],
        'uploadStorage': json['uploadStorage'],
        'tempFolderSize': json['tempFolderSize'],
    };
}

export function StorageInfoDataToJSON(value?: StorageInfoData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalStorage': value.totalStorage,
        'processedStorage': value.processedStorage,
        'uploadStorage': value.uploadStorage,
        'tempFolderSize': value.tempFolderSize,
    };
}

