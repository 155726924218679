/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSurveyUpload
 */
export interface CreateSurveyUpload {
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyUpload
     */
    surveyUUID: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyUpload
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSurveyUpload
     */
    numberOfFilesToUpload: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyUpload
     */
    name: string;
}

/**
 * Check if a given object implements the CreateSurveyUpload interface.
 */
export function instanceOfCreateSurveyUpload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "surveyUUID" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "numberOfFilesToUpload" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateSurveyUploadFromJSON(json: any): CreateSurveyUpload {
    return CreateSurveyUploadFromJSONTyped(json, false);
}

export function CreateSurveyUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSurveyUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyUUID': json['surveyUUID'],
        'type': json['type'],
        'numberOfFilesToUpload': json['numberOfFilesToUpload'],
        'name': json['name'],
    };
}

export function CreateSurveyUploadToJSON(value?: CreateSurveyUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyUUID': value.surveyUUID,
        'type': value.type,
        'numberOfFilesToUpload': value.numberOfFilesToUpload,
        'name': value.name,
    };
}

