/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectInfo } from './ProjectInfo';
import {
    ProjectInfoFromJSON,
    ProjectInfoFromJSONTyped,
    ProjectInfoToJSON,
} from './ProjectInfo';

/**
 * 
 * @export
 * @interface ProjectInfoPage
 */
export interface ProjectInfoPage {
    /**
     * 
     * @type {number}
     * @memberof ProjectInfoPage
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectInfoPage
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ProjectInfo>}
     * @memberof ProjectInfoPage
     */
    projects: Array<ProjectInfo>;
}

/**
 * Check if a given object implements the ProjectInfoPage interface.
 */
export function instanceOfProjectInfoPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "projects" in value;

    return isInstance;
}

export function ProjectInfoPageFromJSON(json: any): ProjectInfoPage {
    return ProjectInfoPageFromJSONTyped(json, false);
}

export function ProjectInfoPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInfoPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': json['offset'],
        'totalCount': json['totalCount'],
        'projects': ((json['projects'] as Array<any>).map(ProjectInfoFromJSON)),
    };
}

export function ProjectInfoPageToJSON(value?: ProjectInfoPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'totalCount': value.totalCount,
        'projects': ((value.projects as Array<any>).map(ProjectInfoToJSON)),
    };
}

