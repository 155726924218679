/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrthophotoMetadata
 */
export interface OrthophotoMetadata {
    /**
     * 
     * @type {string}
     * @memberof OrthophotoMetadata
     */
    inputCoordinateSystem: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrthophotoMetadata
     */
    bounds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrthophotoMetadata
     */
    origin: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof OrthophotoMetadata
     */
    minZoom: number;
    /**
     * 
     * @type {number}
     * @memberof OrthophotoMetadata
     */
    maxZoom: number;
}

/**
 * Check if a given object implements the OrthophotoMetadata interface.
 */
export function instanceOfOrthophotoMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inputCoordinateSystem" in value;
    isInstance = isInstance && "bounds" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "minZoom" in value;
    isInstance = isInstance && "maxZoom" in value;

    return isInstance;
}

export function OrthophotoMetadataFromJSON(json: any): OrthophotoMetadata {
    return OrthophotoMetadataFromJSONTyped(json, false);
}

export function OrthophotoMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrthophotoMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inputCoordinateSystem': json['inputCoordinateSystem'],
        'bounds': json['bounds'],
        'origin': json['origin'],
        'minZoom': json['minZoom'],
        'maxZoom': json['maxZoom'],
    };
}

export function OrthophotoMetadataToJSON(value?: OrthophotoMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inputCoordinateSystem': value.inputCoordinateSystem,
        'bounds': value.bounds,
        'origin': value.origin,
        'minZoom': value.minZoom,
        'maxZoom': value.maxZoom,
    };
}

