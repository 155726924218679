/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardInfoData
 */
export interface DashboardInfoData {
    /**
     * 
     * @type {number}
     * @memberof DashboardInfoData
     */
    totalUsers: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInfoData
     */
    totalProjects: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInfoData
     */
    usedStorage: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInfoData
     */
    dailyJobs: number;
}

/**
 * Check if a given object implements the DashboardInfoData interface.
 */
export function instanceOfDashboardInfoData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalUsers" in value;
    isInstance = isInstance && "totalProjects" in value;
    isInstance = isInstance && "usedStorage" in value;
    isInstance = isInstance && "dailyJobs" in value;

    return isInstance;
}

export function DashboardInfoDataFromJSON(json: any): DashboardInfoData {
    return DashboardInfoDataFromJSONTyped(json, false);
}

export function DashboardInfoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardInfoData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalUsers': json['totalUsers'],
        'totalProjects': json['totalProjects'],
        'usedStorage': json['usedStorage'],
        'dailyJobs': json['dailyJobs'],
    };
}

export function DashboardInfoDataToJSON(value?: DashboardInfoData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalUsers': value.totalUsers,
        'totalProjects': value.totalProjects,
        'usedStorage': value.usedStorage,
        'dailyJobs': value.dailyJobs,
    };
}

