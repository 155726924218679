/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyUploadData
 */
export interface SurveyUploadData {
    /**
     * 
     * @type {string}
     * @memberof SurveyUploadData
     */
    uploadUUID: string;
}

/**
 * Check if a given object implements the SurveyUploadData interface.
 */
export function instanceOfSurveyUploadData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uploadUUID" in value;

    return isInstance;
}

export function SurveyUploadDataFromJSON(json: any): SurveyUploadData {
    return SurveyUploadDataFromJSONTyped(json, false);
}

export function SurveyUploadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyUploadData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadUUID': json['uploadUUID'],
    };
}

export function SurveyUploadDataToJSON(value?: SurveyUploadData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadUUID': value.uploadUUID,
    };
}

