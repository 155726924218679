/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardProjectInfoData } from './DashboardProjectInfoData';
import {
    DashboardProjectInfoDataFromJSON,
    DashboardProjectInfoDataFromJSONTyped,
    DashboardProjectInfoDataToJSON,
} from './DashboardProjectInfoData';

/**
 * 
 * @export
 * @interface GetDashboardProjects200Response
 */
export interface GetDashboardProjects200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDashboardProjects200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardProjects200Response
     */
    error: string;
    /**
     * 
     * @type {number}
     * @memberof GetDashboardProjects200Response
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetDashboardProjects200Response
     */
    totalCount: number;
    /**
     * 
     * @type {Array<DashboardProjectInfoData>}
     * @memberof GetDashboardProjects200Response
     */
    data: Array<DashboardProjectInfoData>;
}

/**
 * Check if a given object implements the GetDashboardProjects200Response interface.
 */
export function instanceOfGetDashboardProjects200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetDashboardProjects200ResponseFromJSON(json: any): GetDashboardProjects200Response {
    return GetDashboardProjects200ResponseFromJSONTyped(json, false);
}

export function GetDashboardProjects200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDashboardProjects200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'offset': json['offset'],
        'totalCount': json['totalCount'],
        'data': ((json['data'] as Array<any>).map(DashboardProjectInfoDataFromJSON)),
    };
}

export function GetDashboardProjects200ResponseToJSON(value?: GetDashboardProjects200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'offset': value.offset,
        'totalCount': value.totalCount,
        'data': ((value.data as Array<any>).map(DashboardProjectInfoDataToJSON)),
    };
}

