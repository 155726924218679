<template>
	<div class="loading">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</template>
<style scoped lang="scss">
.loading {
	--ring-color: #FFF;
	--size: 80px;
	--border-size: 4px;

	display: inline-block;
	position: relative;
	width: var(--size);
	height: var(--size);
	
	
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 80%;
		height: 80%;
		margin: 10%;
		border: var(--border-size) solid var(--ring-color);
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: var(--ring-color) transparent transparent transparent;
	}
	
	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	
	div:nth-child(3) {
		animation-delay: -0.15s;
	}
	
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
	
		100% {
			transform: rotate(360deg);
		}
	}
}

</style>
