/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyInputData } from './SurveyInputData';
import {
    SurveyInputDataFromJSON,
    SurveyInputDataFromJSONTyped,
    SurveyInputDataToJSON,
} from './SurveyInputData';
import type { SurveyResource } from './SurveyResource';
import {
    SurveyResourceFromJSON,
    SurveyResourceFromJSONTyped,
    SurveyResourceToJSON,
} from './SurveyResource';

/**
 * 
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    uuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Survey
     */
    surveyDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    coordinateSystemWKT: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    description: string;
    /**
     * 
     * @type {Array<SurveyInputData>}
     * @memberof Survey
     */
    inputFiles?: Array<SurveyInputData>;
    /**
     * 
     * @type {Array<SurveyResource>}
     * @memberof Survey
     */
    models?: Array<SurveyResource>;
    /**
     * 
     * @type {Array<SurveyResource>}
     * @memberof Survey
     */
    cad?: Array<SurveyResource>;
    /**
     * 
     * @type {Array<SurveyResource>}
     * @memberof Survey
     */
    orthophotos?: Array<SurveyResource>;
    /**
     * 
     * @type {Array<SurveyResource>}
     * @memberof Survey
     */
    pointclouds?: Array<SurveyResource>;
}

/**
 * Check if a given object implements the Survey interface.
 */
export function instanceOfSurvey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "surveyDate" in value;
    isInstance = isInstance && "coordinateSystemWKT" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function SurveyFromJSON(json: any): Survey {
    return SurveyFromJSONTyped(json, false);
}

export function SurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Survey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'surveyDate': (new Date(json['surveyDate'])),
        'coordinateSystemWKT': json['coordinateSystemWKT'],
        'description': json['description'],
        'inputFiles': !exists(json, 'inputFiles') ? undefined : ((json['inputFiles'] as Array<any>).map(SurveyInputDataFromJSON)),
        'models': !exists(json, 'models') ? undefined : ((json['models'] as Array<any>).map(SurveyResourceFromJSON)),
        'cad': !exists(json, 'cad') ? undefined : ((json['cad'] as Array<any>).map(SurveyResourceFromJSON)),
        'orthophotos': !exists(json, 'orthophotos') ? undefined : ((json['orthophotos'] as Array<any>).map(SurveyResourceFromJSON)),
        'pointclouds': !exists(json, 'pointclouds') ? undefined : ((json['pointclouds'] as Array<any>).map(SurveyResourceFromJSON)),
    };
}

export function SurveyToJSON(value?: Survey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'surveyDate': (value.surveyDate.toISOString()),
        'coordinateSystemWKT': value.coordinateSystemWKT,
        'description': value.description,
        'inputFiles': value.inputFiles === undefined ? undefined : ((value.inputFiles as Array<any>).map(SurveyInputDataToJSON)),
        'models': value.models === undefined ? undefined : ((value.models as Array<any>).map(SurveyResourceToJSON)),
        'cad': value.cad === undefined ? undefined : ((value.cad as Array<any>).map(SurveyResourceToJSON)),
        'orthophotos': value.orthophotos === undefined ? undefined : ((value.orthophotos as Array<any>).map(SurveyResourceToJSON)),
        'pointclouds': value.pointclouds === undefined ? undefined : ((value.pointclouds as Array<any>).map(SurveyResourceToJSON)),
    };
}

