/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFileInput,
  AddFileToSurveyInput,
  CreateProjectInput,
  CreateSurveyInput,
  CreateSurveyUpload,
  CreateSurveyUpload200Response,
  DashboardInfo200Response,
  DeleteProjectInput,
  DeleteSurveyInput,
  DeleteSurveyUploadInput,
  GetCompletedOrFailedJobsChartData200Response,
  GetCoordinateSystems200Response,
  GetDashboardProjects200Response,
  GetNewUsersChartData200Response,
  GetProject200Response,
  GetProjectSurvey200Response,
  GetProjects200Response,
  GetStorageInfo200Response,
  GetSubscribedUsers200Response,
  GetSurvey200Response,
  GetUsers200Response,
  ProjectQueryInput,
  QueryInput,
  ResponseData,
  SendFeedback200Response,
  SendFeedbackInput,
  UpdateProjectInput,
  UpdateSurveyInput,
  User200Response,
  UserQueryInput,
} from '../models';
import {
    AddFileInputFromJSON,
    AddFileInputToJSON,
    AddFileToSurveyInputFromJSON,
    AddFileToSurveyInputToJSON,
    CreateProjectInputFromJSON,
    CreateProjectInputToJSON,
    CreateSurveyInputFromJSON,
    CreateSurveyInputToJSON,
    CreateSurveyUploadFromJSON,
    CreateSurveyUploadToJSON,
    CreateSurveyUpload200ResponseFromJSON,
    CreateSurveyUpload200ResponseToJSON,
    DashboardInfo200ResponseFromJSON,
    DashboardInfo200ResponseToJSON,
    DeleteProjectInputFromJSON,
    DeleteProjectInputToJSON,
    DeleteSurveyInputFromJSON,
    DeleteSurveyInputToJSON,
    DeleteSurveyUploadInputFromJSON,
    DeleteSurveyUploadInputToJSON,
    GetCompletedOrFailedJobsChartData200ResponseFromJSON,
    GetCompletedOrFailedJobsChartData200ResponseToJSON,
    GetCoordinateSystems200ResponseFromJSON,
    GetCoordinateSystems200ResponseToJSON,
    GetDashboardProjects200ResponseFromJSON,
    GetDashboardProjects200ResponseToJSON,
    GetNewUsersChartData200ResponseFromJSON,
    GetNewUsersChartData200ResponseToJSON,
    GetProject200ResponseFromJSON,
    GetProject200ResponseToJSON,
    GetProjectSurvey200ResponseFromJSON,
    GetProjectSurvey200ResponseToJSON,
    GetProjects200ResponseFromJSON,
    GetProjects200ResponseToJSON,
    GetStorageInfo200ResponseFromJSON,
    GetStorageInfo200ResponseToJSON,
    GetSubscribedUsers200ResponseFromJSON,
    GetSubscribedUsers200ResponseToJSON,
    GetSurvey200ResponseFromJSON,
    GetSurvey200ResponseToJSON,
    GetUsers200ResponseFromJSON,
    GetUsers200ResponseToJSON,
    ProjectQueryInputFromJSON,
    ProjectQueryInputToJSON,
    QueryInputFromJSON,
    QueryInputToJSON,
    ResponseDataFromJSON,
    ResponseDataToJSON,
    SendFeedback200ResponseFromJSON,
    SendFeedback200ResponseToJSON,
    SendFeedbackInputFromJSON,
    SendFeedbackInputToJSON,
    UpdateProjectInputFromJSON,
    UpdateProjectInputToJSON,
    UpdateSurveyInputFromJSON,
    UpdateSurveyInputToJSON,
    User200ResponseFromJSON,
    User200ResponseToJSON,
    UserQueryInputFromJSON,
    UserQueryInputToJSON,
} from '../models';

export interface AddFileToSurveyRequest {
    addFileToSurveyInput: AddFileToSurveyInput;
}

export interface AddFileToSurveyUploadRequest {
    addFileInput: AddFileInput;
}

export interface CreateProjectRequest {
    createProjectInput: CreateProjectInput;
}

export interface CreateSurveyRequest {
    createSurveyInput: CreateSurveyInput;
}

export interface CreateSurveyUploadRequest {
    createSurveyUpload: CreateSurveyUpload;
}

export interface DeleteProjectRequest {
    deleteProjectInput: DeleteProjectInput;
}

export interface DeleteSurveyRequest {
    deleteSurveyInput: DeleteSurveyInput;
}

export interface DeleteSurveyUploadRequest {
    deleteSurveyUploadInput: DeleteSurveyUploadInput;
}

export interface GetCoordinateSystemsRequest {
    searchText: string;
    limit: number;
}

export interface GetDashboardProjectsRequest {
    projectQueryInput: ProjectQueryInput;
}

export interface GetProjectRequest {
    projectUUID: string;
}

export interface GetProjectSurveyRequest {
    projectUUID: string;
}

export interface GetProjectsRequest {
    offset: number;
    limit: number;
}

export interface GetSubscribedUsersRequest {
    queryInput: QueryInput;
}

export interface GetSurveyRequest {
    surveyUUID: string;
}

export interface GetUsersRequest {
    userQueryInput: UserQueryInput;
}

export interface ProcessSurveyUploadRequest {
    addFileInput: AddFileInput;
}

export interface PromoteSubscribedUserRequest {
    email: string;
}

export interface SendFeedbackRequest {
    sendFeedbackInput: SendFeedbackInput;
}

export interface UpdateProjectRequest {
    updateProjectInput: UpdateProjectInput;
}

export interface UpdateSurveyRequest {
    updateSurveyInput: UpdateSurveyInput;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addFileToSurveyRaw(requestParameters: AddFileToSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.addFileToSurveyInput === null || requestParameters.addFileToSurveyInput === undefined) {
            throw new runtime.RequiredError('addFileToSurveyInput','Required parameter requestParameters.addFileToSurveyInput was null or undefined when calling addFileToSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/add-files-to-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFileToSurveyInputToJSON(requestParameters.addFileToSurveyInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async addFileToSurvey(requestParameters: AddFileToSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.addFileToSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addFileToSurveyUploadRaw(requestParameters: AddFileToSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.addFileInput === null || requestParameters.addFileInput === undefined) {
            throw new runtime.RequiredError('addFileInput','Required parameter requestParameters.addFileInput was null or undefined when calling addFileToSurveyUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/add-files-to-survey-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFileInputToJSON(requestParameters.addFileInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async addFileToSurveyUpload(requestParameters: AddFileToSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.addFileToSurveyUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProject200Response>> {
        if (requestParameters.createProjectInput === null || requestParameters.createProjectInput === undefined) {
            throw new runtime.RequiredError('createProjectInput','Required parameter requestParameters.createProjectInput was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/create-project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectInputToJSON(requestParameters.createProjectInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProject200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProject200Response> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createSurveyRaw(requestParameters: CreateSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSurvey200Response>> {
        if (requestParameters.createSurveyInput === null || requestParameters.createSurveyInput === undefined) {
            throw new runtime.RequiredError('createSurveyInput','Required parameter requestParameters.createSurveyInput was null or undefined when calling createSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/create-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSurveyInputToJSON(requestParameters.createSurveyInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSurvey200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSurvey(requestParameters: CreateSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSurvey200Response> {
        const response = await this.createSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createSurveyUploadRaw(requestParameters: CreateSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSurveyUpload200Response>> {
        if (requestParameters.createSurveyUpload === null || requestParameters.createSurveyUpload === undefined) {
            throw new runtime.RequiredError('createSurveyUpload','Required parameter requestParameters.createSurveyUpload was null or undefined when calling createSurveyUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/create-survey-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSurveyUploadToJSON(requestParameters.createSurveyUpload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSurveyUpload200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSurveyUpload(requestParameters: CreateSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSurveyUpload200Response> {
        const response = await this.createSurveyUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardInfo200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/dashboard-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardInfo200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async dashboardInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardInfo200Response> {
        const response = await this.dashboardInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.deleteProjectInput === null || requestParameters.deleteProjectInput === undefined) {
            throw new runtime.RequiredError('deleteProjectInput','Required parameter requestParameters.deleteProjectInput was null or undefined when calling deleteProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/delete-project`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteProjectInputToJSON(requestParameters.deleteProjectInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.deleteProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSurveyRaw(requestParameters: DeleteSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.deleteSurveyInput === null || requestParameters.deleteSurveyInput === undefined) {
            throw new runtime.RequiredError('deleteSurveyInput','Required parameter requestParameters.deleteSurveyInput was null or undefined when calling deleteSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/delete-survey`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSurveyInputToJSON(requestParameters.deleteSurveyInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async deleteSurvey(requestParameters: DeleteSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.deleteSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSurveyUploadRaw(requestParameters: DeleteSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.deleteSurveyUploadInput === null || requestParameters.deleteSurveyUploadInput === undefined) {
            throw new runtime.RequiredError('deleteSurveyUploadInput','Required parameter requestParameters.deleteSurveyUploadInput was null or undefined when calling deleteSurveyUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/delete-survey-upload`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSurveyUploadInputToJSON(requestParameters.deleteSurveyUploadInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async deleteSurveyUpload(requestParameters: DeleteSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.deleteSurveyUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompletedOrFailedJobsChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompletedOrFailedJobsChartData200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/completed-or-failed-jobs-chart-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompletedOrFailedJobsChartData200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCompletedOrFailedJobsChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompletedOrFailedJobsChartData200Response> {
        const response = await this.getCompletedOrFailedJobsChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCoordinateSystemsRaw(requestParameters: GetCoordinateSystemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCoordinateSystems200Response>> {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText','Required parameter requestParameters.searchText was null or undefined when calling getCoordinateSystems.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getCoordinateSystems.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-coordinate-systems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCoordinateSystems200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCoordinateSystems(requestParameters: GetCoordinateSystemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCoordinateSystems200Response> {
        const response = await this.getCoordinateSystemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDashboardProjectsRaw(requestParameters: GetDashboardProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDashboardProjects200Response>> {
        if (requestParameters.projectQueryInput === null || requestParameters.projectQueryInput === undefined) {
            throw new runtime.RequiredError('projectQueryInput','Required parameter requestParameters.projectQueryInput was null or undefined when calling getDashboardProjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dashboard/get-dashboard-projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectQueryInputToJSON(requestParameters.projectQueryInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDashboardProjects200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDashboardProjects(requestParameters: GetDashboardProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDashboardProjects200Response> {
        const response = await this.getDashboardProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobProcessingTimeChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNewUsersChartData200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/job-processing-time-chart-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNewUsersChartData200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getJobProcessingTimeChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNewUsersChartData200Response> {
        const response = await this.getJobProcessingTimeChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobWaitingTimeChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNewUsersChartData200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/job-waiting-time-chart-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNewUsersChartData200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getJobWaitingTimeChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNewUsersChartData200Response> {
        const response = await this.getJobWaitingTimeChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getJobsRaw(initOverrides);
    }

    /**
     */
    async getNewProjectsChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNewUsersChartData200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/new-projects-chart-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNewUsersChartData200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNewProjectsChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNewUsersChartData200Response> {
        const response = await this.getNewProjectsChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewUsersChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNewUsersChartData200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/new-users-chart-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNewUsersChartData200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNewUsersChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNewUsersChartData200Response> {
        const response = await this.getNewUsersChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProject200Response>> {
        if (requestParameters.projectUUID === null || requestParameters.projectUUID === undefined) {
            throw new runtime.RequiredError('projectUUID','Required parameter requestParameters.projectUUID was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectUUID !== undefined) {
            queryParameters['projectUUID'] = requestParameters.projectUUID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProject200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProject200Response> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectSurveyRaw(requestParameters: GetProjectSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectSurvey200Response>> {
        if (requestParameters.projectUUID === null || requestParameters.projectUUID === undefined) {
            throw new runtime.RequiredError('projectUUID','Required parameter requestParameters.projectUUID was null or undefined when calling getProjectSurvey.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectUUID !== undefined) {
            queryParameters['projectUUID'] = requestParameters.projectUUID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-project-surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectSurvey200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProjectSurvey(requestParameters: GetProjectSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectSurvey200Response> {
        const response = await this.getProjectSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectsRaw(requestParameters: GetProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjects200Response>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getProjects.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getProjects.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjects200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProjects(requestParameters: GetProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjects200Response> {
        const response = await this.getProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStorageInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStorageInfo200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/get-storage-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStorageInfo200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getStorageInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStorageInfo200Response> {
        const response = await this.getStorageInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubscribedUsersRaw(requestParameters: GetSubscribedUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSubscribedUsers200Response>> {
        if (requestParameters.queryInput === null || requestParameters.queryInput === undefined) {
            throw new runtime.RequiredError('queryInput','Required parameter requestParameters.queryInput was null or undefined when calling getSubscribedUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dashboard/get-subscribed-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryInputToJSON(requestParameters.queryInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubscribedUsers200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSubscribedUsers(requestParameters: GetSubscribedUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSubscribedUsers200Response> {
        const response = await this.getSubscribedUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurveyRaw(requestParameters: GetSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSurvey200Response>> {
        if (requestParameters.surveyUUID === null || requestParameters.surveyUUID === undefined) {
            throw new runtime.RequiredError('surveyUUID','Required parameter requestParameters.surveyUUID was null or undefined when calling getSurvey.');
        }

        const queryParameters: any = {};

        if (requestParameters.surveyUUID !== undefined) {
            queryParameters['surveyUUID'] = requestParameters.surveyUUID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/get-survey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSurvey200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSurvey(requestParameters: GetSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSurvey200Response> {
        const response = await this.getSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsers200Response>> {
        if (requestParameters.userQueryInput === null || requestParameters.userQueryInput === undefined) {
            throw new runtime.RequiredError('userQueryInput','Required parameter requestParameters.userQueryInput was null or undefined when calling getUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dashboard/get-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserQueryInputToJSON(requestParameters.userQueryInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsers200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUsers(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsers200Response> {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async indexRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async index(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.indexRaw(initOverrides);
    }

    /**
     */
    async processSurveyUploadRaw(requestParameters: ProcessSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseData>> {
        if (requestParameters.addFileInput === null || requestParameters.addFileInput === undefined) {
            throw new runtime.RequiredError('addFileInput','Required parameter requestParameters.addFileInput was null or undefined when calling processSurveyUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/process-survey-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFileInputToJSON(requestParameters.addFileInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDataFromJSON(jsonValue));
    }

    /**
     */
    async processSurveyUpload(requestParameters: ProcessSurveyUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseData> {
        const response = await this.processSurveyUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async promoteSubscribedUserRaw(requestParameters: PromoteSubscribedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendFeedback200Response>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling promoteSubscribedUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dashboard/promote-subscribed-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendFeedback200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async promoteSubscribedUser(requestParameters: PromoteSubscribedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendFeedback200Response> {
        const response = await this.promoteSubscribedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendFeedbackRaw(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendFeedback200Response>> {
        if (requestParameters.sendFeedbackInput === null || requestParameters.sendFeedbackInput === undefined) {
            throw new runtime.RequiredError('sendFeedbackInput','Required parameter requestParameters.sendFeedbackInput was null or undefined when calling sendFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/send-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendFeedbackInputToJSON(requestParameters.sendFeedbackInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendFeedback200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async sendFeedback(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendFeedback200Response> {
        const response = await this.sendFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectRaw(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProject200Response>> {
        if (requestParameters.updateProjectInput === null || requestParameters.updateProjectInput === undefined) {
            throw new runtime.RequiredError('updateProjectInput','Required parameter requestParameters.updateProjectInput was null or undefined when calling updateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/update-project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectInputToJSON(requestParameters.updateProjectInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProject200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateProject(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProject200Response> {
        const response = await this.updateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSurveyRaw(requestParameters: UpdateSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSurvey200Response>> {
        if (requestParameters.updateSurveyInput === null || requestParameters.updateSurveyInput === undefined) {
            throw new runtime.RequiredError('updateSurveyInput','Required parameter requestParameters.updateSurveyInput was null or undefined when calling updateSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/update-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSurveyInputToJSON(requestParameters.updateSurveyInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSurvey200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateSurvey(requestParameters: UpdateSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSurvey200Response> {
        const response = await this.updateSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => User200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async user(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User200Response> {
        const response = await this.userRaw(initOverrides);
        return await response.value();
    }

}
