/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSubscribedUsers200Response
 */
export interface GetSubscribedUsers200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetSubscribedUsers200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSubscribedUsers200Response
     */
    error: string;
    /**
     * 
     * @type {number}
     * @memberof GetSubscribedUsers200Response
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetSubscribedUsers200Response
     */
    totalCount: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetSubscribedUsers200Response
     */
    data: Array<object>;
}

/**
 * Check if a given object implements the GetSubscribedUsers200Response interface.
 */
export function instanceOfGetSubscribedUsers200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetSubscribedUsers200ResponseFromJSON(json: any): GetSubscribedUsers200Response {
    return GetSubscribedUsers200ResponseFromJSONTyped(json, false);
}

export function GetSubscribedUsers200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSubscribedUsers200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'offset': json['offset'],
        'totalCount': json['totalCount'],
        'data': json['data'],
    };
}

export function GetSubscribedUsers200ResponseToJSON(value?: GetSubscribedUsers200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'offset': value.offset,
        'totalCount': value.totalCount,
        'data': value.data,
    };
}

