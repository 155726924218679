/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Survey } from './Survey';
import {
    SurveyFromJSON,
    SurveyFromJSONTyped,
    SurveyToJSON,
} from './Survey';

/**
 * 
 * @export
 * @interface GetSurvey200Response
 */
export interface GetSurvey200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetSurvey200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSurvey200Response
     */
    error: string;
    /**
     * 
     * @type {Survey}
     * @memberof GetSurvey200Response
     */
    data: Survey;
}

/**
 * Check if a given object implements the GetSurvey200Response interface.
 */
export function instanceOfGetSurvey200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetSurvey200ResponseFromJSON(json: any): GetSurvey200Response {
    return GetSurvey200ResponseFromJSONTyped(json, false);
}

export function GetSurvey200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSurvey200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'data': SurveyFromJSON(json['data']),
    };
}

export function GetSurvey200ResponseToJSON(value?: GetSurvey200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': SurveyToJSON(value.data),
    };
}

