declare let BACKEND_URL;
declare let OAUTH_CLIENT_ID;
declare let OAUTH_REDIRECT_URL;
declare let MAPBOX_API_KEY;
declare let SENTRY_DSN;

export class Constants {
	public static readonly backendUrl = typeof BACKEND_URL !== "undefined" ? BACKEND_URL : "http://localhost:8080";
	public static readonly oauthClientId = typeof OAUTH_CLIENT_ID !== "undefined" ? OAUTH_CLIENT_ID : "c5bbe14c851b637835a3f758ae2e831e";
	public static readonly oauthRedirectUrl = typeof OAUTH_REDIRECT_URL !== "undefined" ? OAUTH_REDIRECT_URL : "http://localhost/login/callback";
	public static readonly oauthAuthorizationUrl = "https://store.3dsurvey.si/oauth/authorize";
	public static readonly oauthTokenUrl = "https://store.3dsurvey.si/oauth/token";
	public static readonly mapBoxAPIKey = typeof MAPBOX_API_KEY !== "undefined" ? MAPBOX_API_KEY : "pk.eyJ1IjoibWl0amFwLTNkc3VydmV5IiwiYSI6ImNsZGQwNndmMTAzazAzbm50c3llZzFzNTIifQ.TZoLr6uETu1p08f5IBEznA";
	public static readonly sentryDsn: string | null = typeof SENTRY_DSN !== "undefined" ? SENTRY_DSN : null;
	public static readonly measureFormattingDecimals = 2;
	public static readonly mapSateliteStyle = "mapbox://styles/mapbox/satellite-streets-v11";
	public static readonly mapStreetStyle = "mapbox://styles/mapbox/dark-v10";
	public static readonly projectsMapStyle = "mapbox://styles/mapbox/streets-v11";
	public static readonly useTusForFileUploads = true;
	public static readonly errorLicenseExpired = "license-expired";
	public static readonly errorUserLimitReached = "user-limit-reached";
}
