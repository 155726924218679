import { UnitsUtils } from "geo-three";
import { format } from "date-fns";
import * as THREE from "three";
import { ProjectInfo, ProjectInfoStatusEnum, Survey } from "@/swagger";
import { createVNode, render } from "vue";

export class Utils {
	static XYZToLatLong(longitude, latitude) {
		const x = (longitude * UnitsUtils.EARTH_ORIGIN ) / 180.0;
		let y = Math.log(Math.tan(((90 + latitude) * Math.PI) / 360.0)) / (Math.PI / 180.0);
		y = (y * UnitsUtils.EARTH_ORIGIN) / 180.0;
		return new THREE.Vector2(x, y);
	}

	private static distanceLatLongHeight(lat1, lon1, z1, lat2, lon2, z2) {
		const R = 6378.137; // Radius of earth in KM
		const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
		const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
		const heightDiff = Math.sqrt(z2 * z2) - Math.sqrt(z1 * z1);

		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d * 1000 + (heightDiff); // meters
	}


	static latLongToXYZ(longitude, latitude) {
		const x = (longitude * UnitsUtils.EARTH_ORIGIN) / 180.0;
		let y = Math.log(Math.tan(((90 + latitude) * Math.PI) / 360.0)) / (Math.PI / 180.0);
		y = (y * UnitsUtils.EARTH_ORIGIN) / 180.0;
		return new THREE.Vector2(x, y);
	}

	public static formatDateTime(date: Date): string {
		return format(date, "dd.MM.yyyy HH.mm");
	}

	public static formatDate(date: Date): string {
		return format(date, "dd.MM.yyyy");
	}

	public static formatUnits(value: number, unit = "m") {
		return `${value.toFixed(2)}${unit}`;
	}

	public static formatSurveyName(survey: Survey): string {
		if (survey && survey.surveyDate){
			return `${survey.description ?? "Survey"} (${Utils.formatDateTime(survey.surveyDate)})`;
		} else {
			return undefined;
		}
	}

	public static formatBytes(value?: number){
		if (!value) {
			value = 0;
		}
		const gb = value/(1000*1000*1000);
		const mb = value/(1000*1000);
		if (gb > 1) {
			return `${gb.toFixed(2)}GB`;
		} else {
			return `${mb.toFixed(2)}MB`;
		}
	}

	static distanceOnMap3d(latLong1: THREE.Vector3, latLong2: THREE.Vector3) {
		const long1 = latLong1.x / UnitsUtils.EARTH_ORIGIN * 180.0;
		let lat1 = latLong1.y / UnitsUtils.EARTH_ORIGIN * 180.0;
		lat1 = 180.0 / Math.PI * (2 * Math.atan(Math.exp(lat1 * Math.PI / 180.0)) - Math.PI / 2.0);

		const long2 = latLong2.x / UnitsUtils.EARTH_ORIGIN * 180.0;
		let lat2 = latLong2.y / UnitsUtils.EARTH_ORIGIN * 180.0;
		lat2 = 180.0 / Math.PI * (2 * Math.atan(Math.exp(lat2 * Math.PI / 180.0)) - Math.PI / 2.0);

		return this.distanceLatLongHeight(lat1, long1, latLong1.z, lat2, long2, latLong2.z);
	}

	static distanceOnMap2d(latLong1: THREE.Vector3, latLong2: THREE.Vector3) {
		const long1 = latLong1.x / UnitsUtils.EARTH_ORIGIN * 180.0;
		let lat1 = latLong1.y / UnitsUtils.EARTH_ORIGIN * 180.0;
		lat1 = 180.0 / Math.PI * (2 * Math.atan(Math.exp(lat1 * Math.PI / 180.0)) - Math.PI / 2.0);

		const long2 = latLong2.x / UnitsUtils.EARTH_ORIGIN * 180.0;
		let lat2 = latLong2.y / UnitsUtils.EARTH_ORIGIN * 180.0;
		lat2 = 180.0 / Math.PI * (2 * Math.atan(Math.exp(lat2 * Math.PI / 180.0)) - Math.PI / 2.0);

		return this.distanceLatLongHeight(lat1, long1, 0, lat2, long2, 0);
	}

	public static distance3d(vec1: THREE.Vector3, vec2: THREE.Vector3) {
		return vec1.distanceTo(vec2);
	}

	public static distance2d(vec1: THREE.Vector3, vec2: THREE.Vector3) {
		return Math.sqrt(Math.pow(vec2.x - vec1.x, 2) + Math.pow(vec2.y - vec1.y, 2));
	}

	public static canViewProject(project: ProjectInfo): boolean {
		return project.status != ProjectInfoStatusEnum.Uploading;
	}

	public static mount(component, { props, children, element, context } = { children: null, context: null, element: null, props: null }) {
		let el = element;
		let vNode = createVNode(component, props, children);
		if (context) {
			vNode.appContext = context;
		}
		if (el) {
			render(vNode, el);
		} else if (typeof document !== "undefined") {
			render(vNode, (el = document.createElement("div")));
		}
		const destroy = () => {
			if (el) render(null, el);
			el = null;
			vNode = null;
		};
		return { vNode, destroy, el };
	}

	public static delay(seconds: number): Promise<void> {
		return new Promise((resolve) => {
			setTimeout(resolve, seconds);
		});
	}

	public static rectanglesIntersect(r1Left: number, r1Top: number, r1Right: number, r1Bottom: number, r2Left: number, r2Top: number, r2Right: number, r2Bottom: number) {
		return r1Left < r2Right && r1Right > r2Left && r1Top > r2Bottom && r1Bottom < r2Top;
	}
}
