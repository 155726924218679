/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardProjectInfoData
 */
export interface DashboardProjectInfoData {
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectInfoData
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectInfoData
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectInfoData
     */
    userEmail: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardProjectInfoData
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DashboardProjectInfoData
     */
    lastUpdateDate: Date;
    /**
     * 
     * @type {number}
     * @memberof DashboardProjectInfoData
     */
    surveyCount: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardProjectInfoData
     */
    uploadSize: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardProjectInfoData
     */
    processedSize: number;
}

/**
 * Check if a given object implements the DashboardProjectInfoData interface.
 */
export function instanceOfDashboardProjectInfoData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "projectName" in value;
    isInstance = isInstance && "userEmail" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "lastUpdateDate" in value;
    isInstance = isInstance && "surveyCount" in value;
    isInstance = isInstance && "uploadSize" in value;
    isInstance = isInstance && "processedSize" in value;

    return isInstance;
}

export function DashboardProjectInfoDataFromJSON(json: any): DashboardProjectInfoData {
    return DashboardProjectInfoDataFromJSONTyped(json, false);
}

export function DashboardProjectInfoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardProjectInfoData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'projectName': json['projectName'],
        'userEmail': json['userEmail'],
        'createdDate': (new Date(json['createdDate'])),
        'lastUpdateDate': (new Date(json['lastUpdateDate'])),
        'surveyCount': json['surveyCount'],
        'uploadSize': json['uploadSize'],
        'processedSize': json['processedSize'],
    };
}

export function DashboardProjectInfoDataToJSON(value?: DashboardProjectInfoData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'projectName': value.projectName,
        'userEmail': value.userEmail,
        'createdDate': (value.createdDate.toISOString()),
        'lastUpdateDate': (value.lastUpdateDate.toISOString()),
        'surveyCount': value.surveyCount,
        'uploadSize': value.uploadSize,
        'processedSize': value.processedSize,
    };
}

