/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CoordinateSystem } from './CoordinateSystem';
import {
    CoordinateSystemFromJSON,
    CoordinateSystemFromJSONTyped,
    CoordinateSystemToJSON,
} from './CoordinateSystem';
import type { Survey } from './Survey';
import {
    SurveyFromJSON,
    SurveyFromJSONTyped,
    SurveyToJSON,
} from './Survey';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    previewImageUrl: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Project
     */
    latLong: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    lastUpdateDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    status: string;
    /**
     * 
     * @type {CoordinateSystem}
     * @memberof Project
     */
    coordinateSystem: CoordinateSystem;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    sharing: ProjectSharingEnum;
    /**
     * 
     * @type {object}
     * @memberof Project
     */
    size: object;
    /**
     * 
     * @type {object}
     * @memberof Project
     */
    processedSize: object;
    /**
     * 
     * @type {object}
     * @memberof Project
     */
    uploadSize: object;
    /**
     * 
     * @type {Array<Survey>}
     * @memberof Project
     */
    surveys?: Array<Survey>;
}


/**
 * @export
 */
export const ProjectSharingEnum = {
    None: 'none',
    AnyoneCanView: 'anyone_can_view'
} as const;
export type ProjectSharingEnum = typeof ProjectSharingEnum[keyof typeof ProjectSharingEnum];


/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "previewImageUrl" in value;
    isInstance = isInstance && "latLong" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "lastUpdateDate" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "coordinateSystem" in value;
    isInstance = isInstance && "sharing" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "processedSize" in value;
    isInstance = isInstance && "uploadSize" in value;

    return isInstance;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'previewImageUrl': json['previewImageUrl'],
        'latLong': json['latLong'],
        'createdDate': (new Date(json['createdDate'])),
        'lastUpdateDate': (new Date(json['lastUpdateDate'])),
        'status': json['status'],
        'coordinateSystem': CoordinateSystemFromJSON(json['coordinateSystem']),
        'sharing': json['sharing'],
        'size': json['size'],
        'processedSize': json['processedSize'],
        'uploadSize': json['uploadSize'],
        'surveys': !exists(json, 'surveys') ? undefined : ((json['surveys'] as Array<any>).map(SurveyFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'previewImageUrl': value.previewImageUrl,
        'latLong': value.latLong,
        'createdDate': (value.createdDate.toISOString()),
        'lastUpdateDate': (value.lastUpdateDate.toISOString()),
        'status': value.status,
        'coordinateSystem': CoordinateSystemToJSON(value.coordinateSystem),
        'sharing': value.sharing,
        'size': value.size,
        'processedSize': value.processedSize,
        'uploadSize': value.uploadSize,
        'surveys': value.surveys === undefined ? undefined : ((value.surveys as Array<any>).map(SurveyToJSON)),
    };
}

