import { Constants } from "@/classes/constants";
import { defineStore } from "pinia";

import PKCE from "js-pkce";
import { useDataStore } from "./data-store";
import { reactive } from "vue";
import { UserData } from "@/swagger";

interface UserStore {
	user?: UserInfo | undefined;
}

interface UserInfo {
	email: string;
	name: string;
	accessToken: string;
	refreshToken: string;
	expires: number;
	admin: boolean;
	usedStorage: number;
	availableStorage: number;
}

const pkce = new PKCE({
	client_id: Constants.oauthClientId,
	redirect_uri: Constants.oauthRedirectUrl,
	authorization_endpoint: Constants.oauthAuthorizationUrl,
	token_endpoint: Constants.oauthTokenUrl,
	requested_scopes: "",
});

export const useUserStore = defineStore("user", () => {
	//Load user data from local storage
	const localData = localStorage.getItem("user");
	const userData = localData ? JSON.parse(localData) : undefined;

	const state = reactive<UserStore>({
		user: userData,
	});
	const dataStore = useDataStore();

	//Refresh user data (name, lastname, storage)
	refreshUserData();

	function isLoggedIn(){
		return state.user != null;
	}

	function login(signup = false) {
		let url = pkce.authorizeUrl();
		// const codeVerifier = (pkce as any).codeVerifier;
		// const challenge = (pkce as any).pkceChallengeFromVerifier();
		// console.log("Login");
		// console.log(url);
		// console.log("codeVerifier", codeVerifier);
		// console.log("challenge", challenge);
		if(signup){
			url += "&type=register"
		}
		setTimeout(() => {
			window.location.replace(url);
		}, 1000);
	}

	function logout(errorMessage?: string) {
		state.user = undefined;
		localStorage.setItem("user", null);
		let url = "/signin";
		if(errorMessage){
			url += `?error=${errorMessage}`;
		}
		window.location.href = url;
	}

	async function handleBrowserLoginCallback(): Promise<boolean> {
		try {
			const resp = await pkce.exchangeForAccessToken(window.location.href);

			if (!resp) {
				return false;
			}

			return loginWithToken(resp.access_token, resp.refresh_token, resp.expires_in);

		} catch (e) {
			console.log("Error handling state:", e);
			return false;
		}
	}

	async function refreshUserData(): Promise<void>{
		if(!state.user){
			return;
		}
		const userJson = await dataStore.getUser();

		if (userJson) {
			//Update user data (but only )
			state.user = {
				...state.user,
				email: userJson.email,
				name: `${userJson.name} ${userJson.lastname}`,
				admin: userJson.admin === true,
				availableStorage: userJson.availableStorage,
				usedStorage: userJson.usedStorage,
			};
			localStorage.setItem("user", JSON.stringify(state.user));
		}
	}

	async function loginWithToken(accessToken: string, refreshToken?: string, expiration?: number): Promise<boolean> {
		try {
			//Get user info
			let userJson: UserData | undefined;

			if (accessToken) {
				await dataStore.setAccessToken(accessToken);
				userJson = await dataStore.getUser();
			}

			if (accessToken && userJson) {
				//Store user data
				const userData: UserInfo = {
					email: userJson.email,
					name: `${userJson.name} ${userJson.lastname}`,
					admin: userJson.admin === true,
					accessToken: accessToken,
					refreshToken: refreshToken,
					expires: Date.now() + expiration,
					availableStorage: userJson.availableStorage,
					usedStorage: userJson.usedStorage,
				};
				localStorage.setItem("user", JSON.stringify(userData));
				state.user = userData;
				return true;
			} else {
				return false;
			}
		} catch (e) {
			console.log("Error handling state:", e);
			return false;
		}
	}

	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.get("accessToken")) {
		loginWithToken(urlSearchParams.get("accessToken") as string, undefined, 360 * 1000);
	}	

	return { state, isLoggedIn, login, logout, handleBrowserLoginCallback, loginWithToken };
});
