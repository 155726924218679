/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSurveyInput
 */
export interface DeleteSurveyInput {
    /**
     * 
     * @type {string}
     * @memberof DeleteSurveyInput
     */
    surveyUUID: string;
}

/**
 * Check if a given object implements the DeleteSurveyInput interface.
 */
export function instanceOfDeleteSurveyInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "surveyUUID" in value;

    return isInstance;
}

export function DeleteSurveyInputFromJSON(json: any): DeleteSurveyInput {
    return DeleteSurveyInputFromJSONTyped(json, false);
}

export function DeleteSurveyInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteSurveyInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyUUID': json['surveyUUID'],
    };
}

export function DeleteSurveyInputToJSON(value?: DeleteSurveyInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyUUID': value.surveyUUID,
    };
}

