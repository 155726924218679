/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDataExtended } from './UserDataExtended';
import {
    UserDataExtendedFromJSON,
    UserDataExtendedFromJSONTyped,
    UserDataExtendedToJSON,
} from './UserDataExtended';

/**
 * 
 * @export
 * @interface GetUsers200Response
 */
export interface GetUsers200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetUsers200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUsers200Response
     */
    error: string;
    /**
     * 
     * @type {number}
     * @memberof GetUsers200Response
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetUsers200Response
     */
    totalCount: number;
    /**
     * 
     * @type {Array<UserDataExtended>}
     * @memberof GetUsers200Response
     */
    data: Array<UserDataExtended>;
}

/**
 * Check if a given object implements the GetUsers200Response interface.
 */
export function instanceOfGetUsers200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetUsers200ResponseFromJSON(json: any): GetUsers200Response {
    return GetUsers200ResponseFromJSONTyped(json, false);
}

export function GetUsers200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsers200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'offset': json['offset'],
        'totalCount': json['totalCount'],
        'data': ((json['data'] as Array<any>).map(UserDataExtendedFromJSON)),
    };
}

export function GetUsers200ResponseToJSON(value?: GetUsers200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'offset': value.offset,
        'totalCount': value.totalCount,
        'data': ((value.data as Array<any>).map(UserDataExtendedToJSON)),
    };
}

