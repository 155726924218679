/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProjectInput
 */
export interface CreateProjectInput {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectInput
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectInput
     */
    coordinateSystemId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectInput
     */
    sharing?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProjectInput
     */
    latLong?: Array<number>;
}

/**
 * Check if a given object implements the CreateProjectInput interface.
 */
export function instanceOfCreateProjectInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateProjectInputFromJSON(json: any): CreateProjectInput {
    return CreateProjectInputFromJSONTyped(json, false);
}

export function CreateProjectInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'coordinateSystemId': !exists(json, 'coordinateSystemId') ? undefined : json['coordinateSystemId'],
        'sharing': !exists(json, 'sharing') ? undefined : json['sharing'],
        'latLong': !exists(json, 'latLong') ? undefined : json['latLong'],
    };
}

export function CreateProjectInputToJSON(value?: CreateProjectInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'coordinateSystemId': value.coordinateSystemId,
        'sharing': value.sharing,
        'latLong': value.latLong,
    };
}

