import { Constants } from "@/classes/constants";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { BarChartData, Configuration, CoordinateSystem, DashboardInfoData, DefaultApi, GetDashboardProjects200Response, GetDashboardProjectsRequest, GetSubscribedUsers200Response, GetUsers200Response, MultiBarChartData, Project, ProjectInfo, ProjectQueryInput, ResponseContext, StorageInfoData, Survey, UpdateProjectInputSharingEnum, UserData, UserDataExtended, UserQueryInput } from "../swagger";
import { useUserStore } from "@/store/user-store";

interface ProjectsStoreData {
	projects: ProjectInfo[];
	accessToken?: string;
}

export const useDataStore = defineStore("data", () => {
	const userData = localStorage.getItem("user");
	let userJson: any;
	try {
		if (userData) {
			userJson = JSON.parse(userData);
		}
	} catch (e) {
		console.error("Error parsing local storage");
	}

	const accessToken = userJson?.accessToken;

	let headers: any;
	if (accessToken) {
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	function afterRequest(context:ResponseContext): Promise<void>{
		if(context.response.status == 403 && !window.location.href.includes("/signin")){
			//403 is license expired
			console.log("Request 403... signing out");
			const userStore = useUserStore();
			userStore.logout(Constants.errorLicenseExpired);
		}else if(context.response.status == 406 && !window.location.href.includes("/signin")){
			//406 User limit was reached
			console.log("Request 406... signing out");
			const userStore = useUserStore();
			userStore.logout(Constants.errorUserLimitReached);
		}else if(context.response.status == 401 && !window.location.href.includes("/signin")){
			console.log("Request 401... signing out");
			const userStore = useUserStore();
			userStore.logout();
		}
		return Promise.resolve();
	}

	//Get user data from our backend
	let api = new DefaultApi(
		new Configuration({
			basePath: Constants.backendUrl,
			headers: headers,
			middleware: [{
				post: afterRequest,
			}]
		}),
	);

	const state = reactive<ProjectsStoreData>({
		projects: [],
	});
	async function setAccessToken(accessToken: string) {
		//Get user data from our backend
		api = new DefaultApi(
			new Configuration({
				basePath: Constants.backendUrl,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				middleware: [{
					post: afterRequest
				}]
			}),
		);
	}

	async function getUser(): Promise<UserData | undefined> {
		const response = await api.user();
		if (response.success) {
			return response.data;
		} else {
			console.error("Error making user response", response.error);
		}
	}

	async function getProjects(force = false): Promise<ProjectInfo[] | undefined> {
		if(!force && state.projects.length > 0){
			return state.projects;
		}
		const response = await api.getProjects({ offset: 0, limit: 100 });
		if (response.success) {
			state.projects = response.data.projects;
			return state.projects;
		} else {
			console.error("Error making user response", response.error);
		}
	}

	async function getProject(uuid:string): Promise<Project | undefined> {
		const response = await api.getProject({
			projectUUID: uuid
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error making project response", response.error);
		}
	}

	async function getSurvey(uuid:string): Promise<Survey | undefined> {
		const response = await api.getSurvey({
			surveyUUID: uuid
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error making project response", response.error);
		}
	}

	async function createProject(name:string, coordinateSystemId?: number, latLong?: number[]): Promise<Project | undefined> {
		const response = await api.createProject({
			createProjectInput: {
				name: name,
				coordinateSystemId: coordinateSystemId,
				latLong: latLong
			}
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error making project response", response.error);
		}
	}

	async function updateProject(projectUUID:string, name?: string, coordinateSystemId?: number, latLong?: number[], sharing?: UpdateProjectInputSharingEnum): Promise<Project | undefined> {
		const response = await api.updateProject({
			updateProjectInput: {
				projectUUID: projectUUID,
				coordinateSystemId: coordinateSystemId,
				latLong: latLong,
				name: name,
				sharing: sharing
			}
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error making project response", response.error);
		}
	}

	async function createSurvey(projectUUID:string, date: Date, description?: string): Promise<Survey | undefined> {
		const response = await api.createSurvey({
			createSurveyInput: {
				projectUUID: projectUUID,
				date: date,
				description: description
			}
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error creating survey:", response.error);
		}
	}

	async function updateSurvey(surveyUUID:string, date: Date, description?: string): Promise<Survey | undefined> {
		const response = await api.updateSurvey({
			updateSurveyInput: {
				surveyUUID: surveyUUID,
				date: date,
				description: description
			}
		});
		if (response.success) {
			return response.data;
		} else {
			console.error("Error updating survey:", response.error);
		}
	}

	async function deleteProject(projectUUID: string): Promise<boolean>{
		const res = await api.deleteProject({
			deleteProjectInput: {
				projectUUID : projectUUID
			}
		});

		return res.success;
	}

	async function deleteSurvey(surveyUUID: string): Promise<boolean>{
		const res = await api.deleteSurvey({
			deleteSurveyInput: {
				surveyUUID : surveyUUID
			}
		});

		return res.success;
	}

	async function createSurveyUpload(surveyUUID:string, name: string,type: string, numberOfFiles?: number):Promise<string | undefined>{
		const res = await api.createSurveyUpload({
			createSurveyUpload: {
				surveyUUID: surveyUUID,
				name : name,
				type : type,
				numberOfFilesToUpload: numberOfFiles
			}
		});
		if(res.success){
			return res.data.uploadUUID;
		}else{
			return undefined;
		}
	}

	async function reprocessSurveyUpload(uploadUUID:string):Promise<boolean>{
		const res = await api.processSurveyUpload({
			addFileInput: {
				uploadUUID: uploadUUID
			}
		});
		return res.success;
	}

	async function deleteSurveyUpload(uploadUUID: string): Promise<boolean>{
		const res = await api.deleteSurveyUpload({
			deleteSurveyUploadInput: {
				uploadUUID: uploadUUID
			}
		});

		return res.success;
	}

	async function getCoordinateSystems(searchText: string): Promise<CoordinateSystem[]>{
		const res = await api.getCoordinateSystems({
			searchText: searchText,
			limit: 25
		});

		return res.data;
	}

	async function sendFeedback(location: string, message: string): Promise<boolean>{
		const res = await api.sendFeedback({
			sendFeedbackInput: {
				location: location,
				message: message
			}
		});

		return res.data as any === true;
	}

	async function getDashboardInfo(): Promise<DashboardInfoData | undefined>{
		const res = await api.dashboardInfo();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getNewUsersChartData(): Promise<BarChartData | undefined>{
		const res = await api.getNewUsersChartData();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getNewProjectsChartData(): Promise<BarChartData | undefined>{
		const res = await api.getNewProjectsChartData();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getCompletedOrFailedJobsChartData(): Promise<MultiBarChartData | undefined>{
		const res = await api.getCompletedOrFailedJobsChartData();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getJobProcessingChartData(): Promise<BarChartData | undefined>{
		const res = await api.getJobProcessingTimeChartData();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getJobWaitingTimeChartData(): Promise<BarChartData | undefined>{
		const res = await api.getJobWaitingTimeChartData();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getUsers(query: UserQueryInput): Promise<GetUsers200Response | undefined>{
		const res = await api.getUsers({
			userQueryInput: query
		});
		if(res.data){
			return res;
		}else{
			return undefined;
		}
	}

	async function getSubscribedUsers(offset?: number, limit?: number): Promise<GetSubscribedUsers200Response | undefined>{
		const res = await api.getSubscribedUsers({
			queryInput: {
				searchString: undefined,
				limit: limit,
				offset: offset
			}
		});
		if(res){
			return res;
		}else{
			return undefined;
		}
	}

	async function promoteSubscribedUser(email: string): Promise<boolean>{
		const res = await api.promoteSubscribedUser({
			email: email
		});
		if(res.data){
			return res.data as any;
		}else{
			return undefined;
		}
	}

	async function getStorageInfo(): Promise<StorageInfoData>{
		const res = await api.getStorageInfo();
		if(res.data){
			return res.data;
		}else{
			return undefined;
		}
	}

	async function getDashboardProjects(query: ProjectQueryInput): Promise<GetDashboardProjects200Response | undefined>{
		const res = await api.getDashboardProjects({
			projectQueryInput: query
		});
		if(res && res.data){
			return res;
		}else{
			return undefined;
		}
	}

	return { 
		state, 
		setAccessToken,
		getUser, 
		getProjects, 
		getProject, 
		getSurvey,
		getCoordinateSystems,
		getDashboardInfo,
		getNewUsersChartData,
		getNewProjectsChartData,
		getCompletedOrFailedJobsChartData,
		getJobProcessingChartData,
		getJobWaitingTimeChartData,
		getUsers,
		getSubscribedUsers,
		getStorageInfo,
		getDashboardProjects,
		promoteSubscribedUser,
		createProject, 
		createSurvey, 
		updateSurvey,
		createSurveyUpload,
		updateProject,
		reprocessSurveyUpload,
		deleteProject,
		deleteSurvey,
		deleteSurveyUpload,
		sendFeedback
	};
});
