/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoordinateSystem
 */
export interface CoordinateSystem {
    /**
     * 
     * @type {number}
     * @memberof CoordinateSystem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CoordinateSystem
     */
    epsgCode: string;
    /**
     * 
     * @type {string}
     * @memberof CoordinateSystem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CoordinateSystem
     */
    wktString: string;
}

/**
 * Check if a given object implements the CoordinateSystem interface.
 */
export function instanceOfCoordinateSystem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "epsgCode" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "wktString" in value;

    return isInstance;
}

export function CoordinateSystemFromJSON(json: any): CoordinateSystem {
    return CoordinateSystemFromJSONTyped(json, false);
}

export function CoordinateSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoordinateSystem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'epsgCode': json['epsgCode'],
        'description': json['description'],
        'wktString': json['wktString'],
    };
}

export function CoordinateSystemToJSON(value?: CoordinateSystem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'epsgCode': value.epsgCode,
        'description': value.description,
        'wktString': value.wktString,
    };
}

