/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryInput
 */
export interface QueryInput {
    /**
     * 
     * @type {string}
     * @memberof QueryInput
     */
    searchString: string;
    /**
     * 
     * @type {number}
     * @memberof QueryInput
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof QueryInput
     */
    limit: number;
}

/**
 * Check if a given object implements the QueryInput interface.
 */
export function instanceOfQueryInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchString" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "limit" in value;

    return isInstance;
}

export function QueryInputFromJSON(json: any): QueryInput {
    return QueryInputFromJSONTyped(json, false);
}

export function QueryInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchString': json['searchString'],
        'offset': json['offset'],
        'limit': json['limit'],
    };
}

export function QueryInputToJSON(value?: QueryInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchString': value.searchString,
        'offset': value.offset,
        'limit': value.limit,
    };
}

