/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserData } from './UserData';
import {
    UserDataFromJSON,
    UserDataFromJSONTyped,
    UserDataToJSON,
} from './UserData';

/**
 * 
 * @export
 * @interface User200Response
 */
export interface User200Response {
    /**
     * 
     * @type {boolean}
     * @memberof User200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof User200Response
     */
    error: string;
    /**
     * 
     * @type {UserData}
     * @memberof User200Response
     */
    data: UserData;
}

/**
 * Check if a given object implements the User200Response interface.
 */
export function instanceOfUser200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function User200ResponseFromJSON(json: any): User200Response {
    return User200ResponseFromJSONTyped(json, false);
}

export function User200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): User200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'data': UserDataFromJSON(json['data']),
    };
}

export function User200ResponseToJSON(value?: User200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': UserDataToJSON(value.data),
    };
}

