/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDataExtended
 */
export interface UserDataExtended {
    /**
     * 
     * @type {string}
     * @memberof UserDataExtended
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataExtended
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataExtended
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataExtended
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDataExtended
     */
    createdDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDataExtended
     */
    admin: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UserDataExtended
     */
    projectCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataExtended
     */
    surveyCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataExtended
     */
    uploadStorage: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataExtended
     */
    usedStorage: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataExtended
     */
    availableStorage: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDataExtended
     */
    licenseValid: boolean;
}

/**
 * Check if a given object implements the UserDataExtended interface.
 */
export function instanceOfUserDataExtended(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "lastname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "projectCount" in value;
    isInstance = isInstance && "surveyCount" in value;
    isInstance = isInstance && "uploadStorage" in value;
    isInstance = isInstance && "usedStorage" in value;
    isInstance = isInstance && "availableStorage" in value;
    isInstance = isInstance && "licenseValid" in value;

    return isInstance;
}

export function UserDataExtendedFromJSON(json: any): UserDataExtended {
    return UserDataExtendedFromJSONTyped(json, false);
}

export function UserDataExtendedFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDataExtended {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'lastname': json['lastname'],
        'email': json['email'],
        'createdDate': (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'admin': json['admin'],
        'projectCount': json['projectCount'],
        'surveyCount': json['surveyCount'],
        'uploadStorage': json['uploadStorage'],
        'usedStorage': json['usedStorage'],
        'availableStorage': json['availableStorage'],
        'licenseValid': json['licenseValid'],
    };
}

export function UserDataExtendedToJSON(value?: UserDataExtended | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'lastname': value.lastname,
        'email': value.email,
        'createdDate': (value.createdDate === null ? null : value.createdDate.toISOString()),
        'admin': value.admin,
        'projectCount': value.projectCount,
        'surveyCount': value.surveyCount,
        'uploadStorage': value.uploadStorage,
        'usedStorage': value.usedStorage,
        'availableStorage': value.availableStorage,
        'licenseValid': value.licenseValid,
    };
}

