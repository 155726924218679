/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectInfo
 */
export interface ProjectInfo {
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    status: ProjectInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    previewImageUrl: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectInfo
     */
    latLong: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInfo
     */
    lastUpdateDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInfo
     */
    firstSurveyDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInfo
     */
    lastSurveyDate: Date;
    /**
     * 
     * @type {number}
     * @memberof ProjectInfo
     */
    numberOfSurveys: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectInfo
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    sharing: ProjectInfoSharingEnum;
}


/**
 * @export
 */
export const ProjectInfoStatusEnum = {
    Uploading: 'uploading',
    Processing: 'processing',
    Processed: 'processed',
    Partial: 'partial'
} as const;
export type ProjectInfoStatusEnum = typeof ProjectInfoStatusEnum[keyof typeof ProjectInfoStatusEnum];

/**
 * @export
 */
export const ProjectInfoSharingEnum = {
    None: 'none',
    AnyoneCanView: 'anyone_can_view'
} as const;
export type ProjectInfoSharingEnum = typeof ProjectInfoSharingEnum[keyof typeof ProjectInfoSharingEnum];


/**
 * Check if a given object implements the ProjectInfo interface.
 */
export function instanceOfProjectInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "previewImageUrl" in value;
    isInstance = isInstance && "latLong" in value;
    isInstance = isInstance && "lastUpdateDate" in value;
    isInstance = isInstance && "firstSurveyDate" in value;
    isInstance = isInstance && "lastSurveyDate" in value;
    isInstance = isInstance && "numberOfSurveys" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sharing" in value;

    return isInstance;
}

export function ProjectInfoFromJSON(json: any): ProjectInfo {
    return ProjectInfoFromJSONTyped(json, false);
}

export function ProjectInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'status': json['status'],
        'previewImageUrl': json['previewImageUrl'],
        'latLong': json['latLong'],
        'lastUpdateDate': (new Date(json['lastUpdateDate'])),
        'firstSurveyDate': (new Date(json['firstSurveyDate'])),
        'lastSurveyDate': (new Date(json['lastSurveyDate'])),
        'numberOfSurveys': json['numberOfSurveys'],
        'size': json['size'],
        'sharing': json['sharing'],
    };
}

export function ProjectInfoToJSON(value?: ProjectInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'status': value.status,
        'previewImageUrl': value.previewImageUrl,
        'latLong': value.latLong,
        'lastUpdateDate': (value.lastUpdateDate.toISOString()),
        'firstSurveyDate': (value.firstSurveyDate.toISOString()),
        'lastSurveyDate': (value.lastSurveyDate.toISOString()),
        'numberOfSurveys': value.numberOfSurveys,
        'size': value.size,
        'sharing': value.sharing,
    };
}

