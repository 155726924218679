/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ModelInfo,
    instanceOfModelInfo,
    ModelInfoFromJSON,
    ModelInfoFromJSONTyped,
    ModelInfoToJSON,
} from './ModelInfo';
import {
    OrthophotoMetadata,
    instanceOfOrthophotoMetadata,
    OrthophotoMetadataFromJSON,
    OrthophotoMetadataFromJSONTyped,
    OrthophotoMetadataToJSON,
} from './OrthophotoMetadata';

/**
 * @type SurveyResourceAdditionalData
 * 
 * @export
 */
export type SurveyResourceAdditionalData = ModelInfo | OrthophotoMetadata;

export function SurveyResourceAdditionalDataFromJSON(json: any): SurveyResourceAdditionalData {
    return SurveyResourceAdditionalDataFromJSONTyped(json, false);
}

export function SurveyResourceAdditionalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyResourceAdditionalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ModelInfoFromJSONTyped(json, true), ...OrthophotoMetadataFromJSONTyped(json, true) };
}

export function SurveyResourceAdditionalDataToJSON(value?: SurveyResourceAdditionalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfModelInfo(value)) {
        return ModelInfoToJSON(value as ModelInfo);
    }
    if (instanceOfOrthophotoMetadata(value)) {
        return OrthophotoMetadataToJSON(value as OrthophotoMetadata);
    }

    return {};
}

