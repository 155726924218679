/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendFeedbackInput
 */
export interface SendFeedbackInput {
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackInput
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackInput
     */
    message: string;
}

/**
 * Check if a given object implements the SendFeedbackInput interface.
 */
export function instanceOfSendFeedbackInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function SendFeedbackInputFromJSON(json: any): SendFeedbackInput {
    return SendFeedbackInputFromJSONTyped(json, false);
}

export function SendFeedbackInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendFeedbackInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': json['location'],
        'message': json['message'],
    };
}

export function SendFeedbackInputToJSON(value?: SendFeedbackInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': value.location,
        'message': value.message,
    };
}

