/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyResourceAdditionalData } from './SurveyResourceAdditionalData';
import {
    SurveyResourceAdditionalDataFromJSON,
    SurveyResourceAdditionalDataFromJSONTyped,
    SurveyResourceAdditionalDataToJSON,
} from './SurveyResourceAdditionalData';

/**
 * 
 * @export
 * @interface SurveyResource
 */
export interface SurveyResource {
    /**
     * 
     * @type {string}
     * @memberof SurveyResource
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResource
     */
    resourceUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyResource
     */
    files: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof SurveyResource
     */
    size: object;
    /**
     * 
     * @type {SurveyResourceAdditionalData}
     * @memberof SurveyResource
     */
    additionalData: SurveyResourceAdditionalData;
}

/**
 * Check if a given object implements the SurveyResource interface.
 */
export function instanceOfSurveyResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "resourceUrl" in value;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "additionalData" in value;

    return isInstance;
}

export function SurveyResourceFromJSON(json: any): SurveyResource {
    return SurveyResourceFromJSONTyped(json, false);
}

export function SurveyResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'resourceUrl': json['resourceUrl'],
        'files': json['files'],
        'size': json['size'],
        'additionalData': SurveyResourceAdditionalDataFromJSON(json['additionalData']),
    };
}

export function SurveyResourceToJSON(value?: SurveyResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'resourceUrl': value.resourceUrl,
        'files': value.files,
        'size': value.size,
        'additionalData': SurveyResourceAdditionalDataToJSON(value.additionalData),
    };
}

