/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardInfoData } from './DashboardInfoData';
import {
    DashboardInfoDataFromJSON,
    DashboardInfoDataFromJSONTyped,
    DashboardInfoDataToJSON,
} from './DashboardInfoData';

/**
 * 
 * @export
 * @interface DashboardInfo200Response
 */
export interface DashboardInfo200Response {
    /**
     * 
     * @type {boolean}
     * @memberof DashboardInfo200Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardInfo200Response
     */
    error: string;
    /**
     * 
     * @type {DashboardInfoData}
     * @memberof DashboardInfo200Response
     */
    data: DashboardInfoData;
}

/**
 * Check if a given object implements the DashboardInfo200Response interface.
 */
export function instanceOfDashboardInfo200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DashboardInfo200ResponseFromJSON(json: any): DashboardInfo200Response {
    return DashboardInfo200ResponseFromJSONTyped(json, false);
}

export function DashboardInfo200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardInfo200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': json['error'],
        'data': DashboardInfoDataFromJSON(json['data']),
    };
}

export function DashboardInfo200ResponseToJSON(value?: DashboardInfo200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': DashboardInfoDataToJSON(value.data),
    };
}

