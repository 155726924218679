/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSurveyUploadInput
 */
export interface DeleteSurveyUploadInput {
    /**
     * 
     * @type {string}
     * @memberof DeleteSurveyUploadInput
     */
    uploadUUID: string;
}

/**
 * Check if a given object implements the DeleteSurveyUploadInput interface.
 */
export function instanceOfDeleteSurveyUploadInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uploadUUID" in value;

    return isInstance;
}

export function DeleteSurveyUploadInputFromJSON(json: any): DeleteSurveyUploadInput {
    return DeleteSurveyUploadInputFromJSONTyped(json, false);
}

export function DeleteSurveyUploadInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteSurveyUploadInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadUUID': json['uploadUUID'],
    };
}

export function DeleteSurveyUploadInputToJSON(value?: DeleteSurveyUploadInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadUUID': value.uploadUUID,
    };
}

