/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelInfo
 */
export interface ModelInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    quality: ModelInfoQualityEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    modelUrl: string;
}


/**
 * @export
 */
export const ModelInfoQualityEnum = {
    _2k: '2k',
    _4k: '4k',
    _8k: '8k'
} as const;
export type ModelInfoQualityEnum = typeof ModelInfoQualityEnum[keyof typeof ModelInfoQualityEnum];


/**
 * Check if a given object implements the ModelInfo interface.
 */
export function instanceOfModelInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quality" in value;
    isInstance = isInstance && "modelUrl" in value;

    return isInstance;
}

export function ModelInfoFromJSON(json: any): ModelInfo {
    return ModelInfoFromJSONTyped(json, false);
}

export function ModelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quality': json['quality'],
        'modelUrl': json['modelUrl'],
    };
}

export function ModelInfoToJSON(value?: ModelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quality': value.quality,
        'modelUrl': value.modelUrl,
    };
}

